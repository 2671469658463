.navbar {
  --bs-navbar-toggler-focus-width: 0rem;
  font-weight: $font-weight-light;
  background: $navbar-bg;
  transition: background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: background $action-transition-duration $action-transition-timing-function;
  -moz-transition: background $action-transition-duration $action-transition-timing-function;
  -ms-transition: background $action-transition-duration $action-transition-timing-function;

  .navbar-brand-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    background: $sidebar-dark-bg;
    width: $sidebar-width-lg;
    height: $navbar-height;
    padding: 0 $sidebar-menu-padding-x;

    .sidebar-icon-only & {
      padding: 0;
    }

    @media (max-width: 991px) {
      width: 55px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .navbar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: .25rem 0;
      width: 110px;

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        width: calc(#{$sidebar-width-lg} - 120px);
        max-width: 100%;
        margin: auto;
        vertical-align: middle;
      }

      &.brand-logo-mini {
        display: none;

        img {
          width: calc(#{$sidebar-width-icon} - 30px);
          max-width: 100%;
          margin: auto;
        }
      }
    }

    .navbar-toggler {
      color: $sidebar-light-menu-icon-color;
      padding: 0;
      margin-left: auto;

      @media screen and (max-width: 991px) {
        display: none;
      }

      .rtl & {
        margin-left: 0;
        margin-right: auto;
      }

      .sidebar-icon-only & {
        margin-right: auto;
        margin-left: auto;
      }

      .sidebar-dark & {
        color: $sidebar-dark-menu-icon-color;
      }
    }
  }

  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: $navbar-menu-color;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% - #{$sidebar-width-lg});
    height: $navbar-height;
    box-shadow: 18px 1px 20px rgba(0, 0, 0, 0.1);

    @media (max-width: 991px) {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
      flex-grow: 1;
    }

    .navbar-nav-right {
      margin-left: auto;

      .rtl & {
        margin-left: 0;
        margin-right: auto;
      }
    }

    .navbar-toggler {
      border: 0;
      color: inherit;
      height: $navbar-height;
      @include border-radius(0px);
      padding-left: 5px;
      padding-right: 20px;

      &:not(.navbar-toggler-right) {
        font-size: 1.5rem;

        @media (max-width: 991px) {
          display: none;
        }
      }

      &.navbar-toggler-right {
        @media (max-width:991px) {
          padding-left: 15px;
          padding-right: 11px;
          border-right: none;
        }
      }
    }

    .search-form {
      position: relative;
      margin-right: 15px;

      .rtl & {
        margin-right: 0;
        margin-left: 15px;
      }

      i {
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $navbar-menu-icon-color;

        .rtl & {
          left: auto;
          right: 0;
        }
      }

      input {
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 0;
        height: 35px;
        padding: 0 0 0 20px;
        position: relative;
        transition: width 400ms ease, background 400ms ease;

        &:focus {
          background-color: transparent;
          border-bottom: 1px solid $navbar-menu-icon-color;
          border-radius: 0;
          padding-left: 30px;
          cursor: text;
          outline: 0;
          width: 140px;

          .rtl & {
            padding-right: 30px;
            padding-left: 0;
          }
        }
      }
    }

    .count-indicator {
      position: relative;

      .count-symbol,
      .count-number {
        position: absolute;
        border-radius: 100%;
      }

      .count-symbol {
        top: 17px;
        right: -3px;
        width: 10px;
        height: 10px;
        border: 2px solid $white;
      }

      .count-number {
        min-width: 14px;
        height: 14px;
        font-size: .5rem;
        color: $white;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 14px;
        text-align: center;
      }

      &:after {
        display: none;
      }
    }

    .navbar-nav {
      flex-direction: row;
      align-items: center;

      .nav-item {
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
          margin-left: 15px;
          margin-right: 15px;
          height: $navbar-height / 2;
          @include display-flex;
          @include align-items(center);
          cursor: pointer;

          @media (max-width: 767px) {
            margin-left: .8rem;
            margin-right: .8rem;
          }

          i {
            font-size: $navbar-icon-font-size;
            color: $navbar-menu-icon-color;
          }
        }

        .dropdown {
          height: $navbar-height;
          display: flex;
          align-items: center;

          .dropdown-toggle {
            border: 0;
            padding: 0 20px;
            background: transparent;
            @extend .font-weight-normal;

            &:focus {
              box-shadow: none;
            }

            &:after {
              font-size: 14px;
              color: grey;
            }

            @media (min-width: 992px) {
              &::after {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);

                .rtl & {
                  right: auto;
                  left: 0;
                }
              }
            }
          }

          .dropdown-menu {
            @extend .dropdownAnimation;
            margin-top: 0;
            border: none;
            @include border-radius(5px);
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);

            &.navbar-dropdown {
              position: absolute;
              font-size: 0.9rem;
              margin-top: 15px;
              padding: 0;

              .rtl & {
                right: auto;
                left: 0;
              }

              .dropdown-item {
                @extend .d-flex;
                @extend .align-items-center;
                margin-bottom: 0;
                padding: 11px 13px;
                cursor: pointer;

                i {
                  font-size: 17px;
                }

                .ellipsis {
                  max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .rtl & {
                  i {
                    @extend .mr-0;
                    margin-left: 10px;
                  }
                }
              }

              .dropdown-divider {
                margin: 0;
              }
            }
          }

          @media (max-width: 991px) {
            position: static;

            .navbar-dropdown {
              left: 20px;
              right: 20px;
              top: $navbar-height;
              width: 100%;
            }
          }
        }

        &.nav-settings {
          padding-left: 20px;
          padding-right: 5px;

          .nav-link {
            margin-right: 0;
            margin-left: 0;
            background-color: transparent;
          }
        }

        &.message-dropdown {
          .dropdown-toggle {
            padding: 0;
          }

          .count-number {
            top: 7px;
          }
        }

        &.language-dropdown {
          margin-left: 24px;

          .rtl & {
            margin-left: 0;
            margin-right: 24px;
          }

          .dropdown-toggle {
            margin-right: 0;
            margin-left: 0;
            padding-left: 44px;
            border-left: 1px solid #ecf0f4;

            .rtl & {
              padding-left: 20px;
              padding-right: 44px;
              border-left: 0;
              border-right: 1px solid #ecf0f4;
            }

            span {
              .rtl & {
                margin: 0 0.5rem;
              }
            }
          }
        }

        &.nav-profile {
          .nav-link {
            @extend .d-flex;

            .nav-profile-img {
              position: relative;
              width: 32px;
              height: 32px;

              img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
              }

              .availability-status {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                border: 2px solid #ffffff;
                top: 50%;
                transform: translateY(-50%);
                right: -5px;

                &.online {
                  background: theme-color(success);
                }

                &.offline {
                  background: theme-color(danger);
                }

                &.busy {
                  background: theme-color(warning);
                }
              }
            }

            .nav-profile-text {
              margin-left: 1.25rem;

              .rtl & {
                margin-left: 0;
                margin-right: 1.25rem;
              }

              p {
                line-height: 1;
              }

              @media (max-width: 767px) {
                display: none;
              }
            }

            &.dropdown-toggle {
              &:after {
                line-height: 2;
              }
            }
          }
        }
      }

      &.navbar-nav-right {
        @media (min-width: 992px) {
          margin-left: auto;

          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
}

@media (max-width:991px) {
  .navbar {
    flex-direction: row;

    .navbar-brand-wrapper {
      width: 75px;

      .navbar-brand {
        &.brand-logo {
          display: none;
        }

        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width:480px) {
  .navbar {
    .navbar-brand-wrapper {
      width: 55px;

      .brand-logo-mini {
        padding-top: 0px;
      }
    }
  }
}

/* Navbar color variations */
@each $color, $value in $theme-colors {
  .navbar {
    &.navbar-#{$color} {
      .navbar-menu-wrapper {
        background: $value;
        color: $white;

        .nav-profile-text {
          p {
            @extend .text-white;
          }
        }

        .search-field {
          .input-group {

            input {
              color: white;
            }

            i {
              color: $white;
            }
          }
        }
      }
    }
  }
}