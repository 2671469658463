////////// COLOR SYSTEM //////////

$blue: #5E50F9;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #E91E63;
$red: #f96868;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray : #2e383e;
$dodger-blue : #3498db;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;


$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: #434a54,
  gray-light: #aab2bd,
  gray-lighter: #e8eff4,
  gray-lightest: #e6e9ed,
  gray-dark: #0f1531,
  black: #000000
);


$theme-colors: (
  primary: #1bdbe0,
  secondary: #d8d8d8,
  success: #38ce3c,
  info: #8e32e9,
  warning: #ffde73,
  danger: #ff4d6b,
  light: #f8f9fa,
  dark: #3e4b5b
);


////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #ecf0f4;
$footer-bg: $content-bg;
$footer-color: $text-muted;
$border-color: #e8e5e5;
$circle-border: $white;

////////// COLOR VARIABLES //////////



////////// SOCIAL COLORS //////////

$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500
);

////////// SOCIAL COLORS //////////

////////// FONTS//

$type1-light: 'opensans-light', sans-serif;
$type1-regular: 'opensans-regular', sans-serif;
$type1-medium: 'opensans-medium', sans-serif;
$type1-bold: 'opensans-bold', sans-serif;

$type1: 'Open Sans', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-semibold: 700;

$default-font-size: .875rem; // 14px as base font size

$text-muted: #a7afb7;
$body-color: #343a40;

/// FUNCTIONS SELF USED

@function theme-color($key: "danger") {
  @return map-get($theme-colors, $key);
}

////////// FONT VARIABLES //////////

////////// SIDEBAR ////////
$sidebar-width-lg: 240px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: $white;
$sidebar-light-menu-color: #3e4b5b;
$sidebar-light-submenu-color: #888;
$sidebar-light-menu-active-bg: $white;
$sidebar-light-menu-active-color: theme-color(success);
$sidebar-light-menu-hover-bg: darken($sidebar-light-bg, 1%);
$sidebar-light-menu-hover-color: darken($sidebar-light-menu-color, 10%);
$sidebar-light-submenu-hover-color: darken($sidebar-light-submenu-color, 20%);
$sidebar-light-menu-icon-color: #626262;
$sidebar-light-menu-arrow-color: #626262;
$sidebar-light-menu-border-color: #f2edf3;

$sidebar-dark-bg: #181824;
$sidebar-dark-menu-color: $white;
$sidebar-dark-menu-active-bg: darken($sidebar-dark-bg, 10%);
$sidebar-dark-menu-active-color: darken($white, 30%);
$sidebar-dark-menu-hover-bg: darken($sidebar-dark-bg, 10%);
$sidebar-dark-menu-hover-color: color(white);
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: darken($sidebar-dark-submenu-color, 30%);
$sidebar-dark-menu-icon-color: #626262;
$sidebar-dark-menu-arrow-color: #626262;

$sidebar-nav-category-color: theme-color(success);
$sidebar-nav-category-padding: 1rem 0 1.125rem;

$sidebar-nav-link-border-color: rgba($white, 0.11);

$sidebar-menu-font-size: $default-font-size;
$sidebar-menu-padding-y: 0.625rem;
$sidebar-menu-padding-x: 1.75rem;

$sidebar-submenu-font-size: .8125rem;
$sidebar-submenu-item-padding: .75rem 2rem .75rem 2rem;

$sidebar-icon-font-size: 1.125rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: .75rem 1.625rem .75rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;
$sidebar-icon-only-submenu-rtl-padding: 0 1.5rem 0 0;

$rtl-sidebar-submenu-padding: 0 1.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-menu-hover-bg;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// NAVBAR ////////

$navbar-bg: #fff;
$navbar-height: 70px;
$navbar-height-plus-card-margin: 130px;
$navbar-menu-color: #111111;
$navbar-menu-icon-color: #626262;

$navbar-font-size: .875rem;
$navbar-icon-font-size: 1.25rem;

///////// NAVBAR ////////



///////// BUTTONS ////////

$button-fixed-width: 150px;
$btn-padding-y: .875rem;
$btn-padding-x: 2.5rem;
$btn-line-height: 1;

$btn-padding-y-xs: .5rem;
$btn-padding-x-xs: .75rem;

$btn-padding-y-sm: .50rem;
$btn-padding-x-sm: .81rem;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;

$btn-font-size: .875rem;
$btn-font-size-xs: .625rem;
$btn-font-size-sm: .875rem;
$btn-font-size-lg: .875rem;

$btn-border-radius: .1875rem;
$btn-border-radius-xs: .1875rem;
$btn-border-radius-sm: .1875rem;
$btn-border-radius-lg: .1875rem;

///////// BUTTONS ////////



////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size: .75rem;
$tooltip-padding-y: .4rem;
$tooltip-padding-x: .75rem;
$tooltip-border-radius: .375rem;

////////// TOOLTIP VARIABLES //////////



/////////  FORMS /////////

$input-bg: color(white);
$input-border-radius: 2px;
$input-placeholder-color: #c9c8c8;
$input-font-size: .9rem;

$input-padding-y: .875rem;
$input-padding-x: 1.375rem;
$input-line-height: 1;

$input-padding-y-xs: .5rem;
$input-padding-x-xs: .75rem;

$input-padding-y-sm: .50rem;
$input-padding-x-sm: .81rem;

$input-padding-y-lg: .94rem;
$input-padding-x-lg: 1.94rem;

$input-height: 2.875rem;
$input-height-sm: 2.575rem;
$input-height-lg: 3.175rem;

///////// FORMS /////////

////////  DROPDOWNS ///////

$dropdown-border-color: $border-color;
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-header-color: $body-color;
$dropdown-danger-border-width: 1px;
////////  DROPDOWNS ///////

//////// TABLES ////////

// $border-width: 1px;
// $border-color: $gray-300 !default;

// $table-accent-bg: $content-bg;
// $table-hover-bg: $content-bg;
$table-cell-padding: .9375rem;
$table-border-color: $border-color;

$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);
// $table-border-width: $border-width;
// $table-border-color: $border-color !default;
// $table-cell-padding-sm: .3rem;
// $table-bg-level: -9;
// $table-border-level: -6;

//////// TABLES ////////



////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$border-property: 1px solid $border-color;
$card-spacing-y: 1.875rem;
$card-padding-y: 1.875rem;
$card-padding-x: 1.875rem;
$card-border-radius: 0;
$card-description-color: #76838f;
$grid-gutter-width: 20px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
$card-bg-varient: #fff;
////////// OTHER VARIABLES //////////



////////// BREAD CRUMBS VARIABLES //////////

// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: .5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
$breadcrumb-bg: transparent;
$breadcrumb-border-color: $border-color;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: "/";

// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
$breadcrumb-custom-item-color: $black;
$breadcrumb-item-bg: #dbe3e6;

////////// BREAD CRUMBS VARIABLES //////////



////////// MODALS VARIABLES //////////

$modal-inner-padding: 15px;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-bg: $content-bg;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, .5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, .5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: .5;

$modal-header-border-color: $border-color;
$modal-content-border-color: $border-color;
$modal-footer-border-color: $border-color;

$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;

$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;

$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;

$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;

$modal-lg: 90%;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform .4s ease;

////////// MODALS VARIABLES //////////

/////////  TABS VARIABLES //////////
$nav-tabs-border-color: $border-color;
$nav-tabs-link-bg: #f6f8fa;
$nav-tabs-link-color: #000000;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg: #fcfcfd;
$text-gray: gray;
$circle-img-border: $white;
////////   TABS VARIABLES /////////

////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
////////   CALENDAR  //////////////

///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
///////// SETTINGS PANEL ////////

////////// Vendor file asset path //////////
$mdi-font-path: "~@mdi/font/fonts";
$fa-font-path : '~font-awesome/fonts/';
$flag-icon-css-path : '~flag-icon-css/flags/';
$simple-line-font-path : '~simple-line-icons/fonts/';


///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// TICKETS-TAB ///////
$tab-head-bg: darken($content-bg, 2%);
$accent-color: $white;
///////// TICKETS-TAB ///////

///////// LANDING PAGE ///////
$landing-bg: #1a142dfa;
$landing-bg-color: $white;
///////// LANDING PAGE ///////

///////// CHATS ///////
$chat-container-height: 100%;
$chat-bottom-btn: $white;
$form-focus-bg: $white;
///////// CHATS ///////

///////// KANBAN ///////
$private-btn-bg: darken($content-bg, 5%);
///////// KANBAN ///////