/* Utilities */

// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }

        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }

        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }

        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if $size !=0 {
        .m#{$infix}-n#{$size} {
          margin: -$length !important;
        }

        .mt#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-top: -$length !important;
        }

        .mr#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-right: -$length !important;
        }

        .mb#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-bottom: -$length !important;
        }

        .ml#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-left: -$length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto {
      margin: auto !important;
    }

    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }

    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }

    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }

    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}

.grid-margin {
  margin-bottom: $card-spacing-y;
}

.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-sm {
  width: 43px;
  height: 43px;
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 35px;
  height: 35px;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);

  >.card {
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-small {
  font-size: 12px;
}

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.font-weight-normal {
  font-weight: $font-weight-regular;
}

.cursor-pointer {
  cursor: pointer;
}

.image-grouped {
  display: flex;

  .text-avatar,
  img {
    @extend .img-ss;
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $card-bg;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width";

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;

    }
  }

  .text-avatar {
    @extend .bg-inverse-primary;
    color: theme-color(primary);
    font-size: 11px;
    font-weight: 600;
  }
}

.mr-0 {
  margin-right: 0;
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0;
}

.mr-auto {
  margin-right: auto;
}

.ml-2 {
  margin-left: 2;
}

.mr-2 {
  margin-right: 0.25rem;
}

.mw-9 {
  min-width: 9rem;
}

.hr {
  border: 1px solid theme-color(secondary);
  ;
}

.body-full-height {
  min-height: calc(100vh - #{$navbar-height-plus-card-margin});
}

@media screen and (max-width: 767px) {
  .body-full-height {
    display: block;
    min-height: 10px;
  }
}

.modal-header-bg {
  border: 1px solid theme-color(primary);
  background-color: theme-color(primary);
  color: white;
  height: 3rem;
}