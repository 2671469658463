.print-only {
    display: none;
}

@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }

    @page {
        margin-top: -10px;
    }

    body {
        margin: -2rem;
    }

    table {
        width: 100%;
    }

    .print-only {
        padding: 0 $card-padding-x;
        display: block;
        width: 100%;
    }

    .client-info {
        width: 100%;
        text-align: center;
    }

    .client-logo-image {
        height: 50px;
    }

    .print-student-summary {
        font-size: 1rem;

        &.row {
            width: 50%;
            float: left;
            padding-right: 3rem;
        }
    }

}